<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading"
        rounded="sm"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header
            class="justify-content-start p-0 pb-1 mb-1 border-bottom"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2">
              Milestone Manager
            </h3>
          </b-card-header>
          <b-row>
            <b-col>
              <!-- search input -->
              <div class="custom-search d-flex justify-content-start">
                <b-form-group
                  class="form-inline"
                  label="Search"
                  label-size="sm"
                >
                  <b-form-input
                    v-model="searchTerm"
                    class="d-inline-block mr-1"
                    placeholder="Search reviews"
                    type="text"
                  />
                  <b-button
                    v-b-modal.addMilestone
                    variant="primary"
                  >
                    <feather-icon icon="PlusIcon" />
                    Add Milestone
                  </b-button>
                </b-form-group>
              </div>

              <!-- table -->
              <vue-good-table
                :columns="columns"
                :pagination-options="{
                  enabled: true,
                  perPage:pageLength
                }"
                :rows="rows"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <!-- Column: Title -->
                  <span
                    v-if="props.column.field === 'title'"
                    class="d-flex justify-content-between align-items-center"
                  ><span>
                     <b-badge
                       v-b-tooltip="'Milestone Number'"
                       class="mr-50 mb-25"
                       variant="primary"
                     >{{ props.row.stage_number }}</b-badge>
                     {{ props.row.title }}
                     <!-- A button with edit icon, no space, and a tooltip saying "Edit Title" -->
                     <b-button
                       v-b-tooltip.hover="'Edit Title'"
                       size="sm"
                       variant="flat-secondary"
                       @click="selectedRow=props.row.id; $bvModal.show('editMilestone'); milestoneTitle=props.row.title"
                     >
                       <feather-icon icon="EditIcon" />
                     </b-button>
                     <!--                    Delete Icon -->
                     <b-button
                       v-b-tooltip.hover="'Delete Milestone'"
                       size="sm"
                       variant="flat-danger"
                       @click="deleteMilestone(props.row.id)"
                     >
                       <feather-icon icon="TrashIcon" />
                     </b-button></span>
                    <span><b-button
                      class="ml-auto"
                      size="sm"
                      variant="outline-primary"
                      :to="{name: 'progress-milestone-stages', params: {
                        pid: $route.params.pid, aid: $route.params.aid, id: props.row.id
                      }}"
                    >View Stages
                    </b-button></span>
                  </span>
                  <!-- Column: Status -->
                  <span
                    v-else-if="props.column.field === 'recommendations'"
                  >
                    <div class="d-flex justify-content-between align-items-center">
                      <b-badge
                        v-if="props.row.programs_progressrecordtables[0].status"
                        size="sm"
                        class="mr-50"
                        :variant="statusVariant(props.row.programs_progressrecordtables[0].status)"
                      >{{ props.row.programs_progressrecordtables[0].status }}</b-badge>
                      <b-button
                        class="ml-auto"
                        size="sm"
                        variant="flat-primary"
                        @click="selectedRow=props.row.originalIndex; updateModal = Object.assign({},props.row.programs_progressrecordtables[0]); $bvModal.show('recommendationModal')"
                      >Update</b-button></div>
                    <div class="font-small-4 pt-50 pl-50">{{
                      props.row.programs_progressrecordtables[0].recommendations
                    }}</div>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap ">
                        Showing 1 to
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                      <b-pagination
                        :per-page="pageLength"
                        :total-rows="props.total"
                        :value="1"
                        align="right"
                        class="mt-1 mb-0"
                        first-number
                        last-number
                        next-class="next-item"
                        prev-class="prev-item"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
              <b-modal
                v-if="rows[selectedRow]"
                id="recommendationModal"
                no-close-on-backdrop
                ok-only
                ok-title="Update"
                title="Recommendations"
                @ok="updateStatus"
              >
                <b-form-group
                  label="Status"
                >
                  <v-select
                    v-model="updateModal.status"
                    :options="['Excellent', 'Good', 'Satisfactory', 'Unsatisfactory', 'Incomplete']"
                    placeholder="Select from list"
                  />
                </b-form-group>
                <b-form-group
                  label="Recommendations"
                >
                  <b-form-input
                    v-model="updateModal.recommendations"
                    placeholder="Your recommendation for startup"
                  />
                </b-form-group>

              </b-modal>
              <b-modal
                id="addMilestone"
                no-close-on-backdrop
                ok-only
                ok-title="Add"
                title="Add Milestone"
                @ok="addMilestone"
              >
                <b-form-group
                  label="Milestone Title"
                >
                  <b-form-input
                    v-model="milestoneTitle"
                    placeholder="Milestone Title"
                  />
                </b-form-group>
              </b-modal>
              <b-modal
                id="editMilestone"
                no-close-on-backdrop
                ok-only
                ok-title="Update"
                title="Edit Milestone"
                @ok="editMilestone(selectedRow)"
              >
                <b-form-group
                  label="Milestone Title"
                >
                  <b-form-input
                    v-model="milestoneTitle"
                    placeholder="Milestone Title"
                  />
                </b-form-group>
              </b-modal>
            </b-col>
          </b-row>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>
<script>

import {
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BBadge,
    BCardHeader,
    vSelect,
    // Treeselect,
    BModal,
  },
  data() {
    return {
      searchTerm: '',
      applicationForm: null,
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Milestone',
          field: 'title',
        },
        {
          label: 'Recommendations',
          field: 'recommendations',
        },
      ],
      rows: [],
      reviewTitle: null,
      selectedOperation: null,
      reviewType: null,
      milestoneTitle: null,
      updateModal: {
        recommendations: null,
        status: null,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Excellent: 'light-success',
        Good: 'light-success',
        Satisfactory: 'light-warning',
        Unsatisfactory: 'light-danger',
        Incomplete: 'light-secondary',
      }
      return status => statusColor[status]
    },
  },
  methods: {
    addMilestone() {
      this.$apollo.mutate(
        {
          mutation: gql`mutation {
              insert_programs_progressstagetable_one(object: {title: "${this.milestoneTitle}", startup_participant_id: ${this.$route.params.aid}, stage_number: "${this.rows.length + 1}"}) {
                id
              }
            }`,
          update: (store, { data: { insert_programs_progressstagetable_one } }) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: insert_programs_progressstagetable_one.id ? 'Milestone added successfully' : 'Failed to add',
                icon: insert_programs_progressstagetable_one.id ? 'CheckIcon' : 'XIcon',
                variant: insert_programs_progressstagetable_one.id ? 'success' : 'warning',
              },
            })
            this.$apollo.queries.rows.refetch()
          },
        },
      )
      this.milestoneTitle = null
    },
    updateStatus() {
      // eslint-disable-next-line no-underscore-dangle
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($object: programs_progressrecordtable_insert_input!) {
          insert_programs_progressrecordtable_one(object: $object, on_conflict: {constraint: programs_progressrecordtable_pkey, update_columns: [status, recommendations]}) {
            id
          }
        }`,
        variables: {
          object: this.updateModal,
        },
        update: (store, { data: { insert_programs_progressrecordtable_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_progressrecordtable_one.id ? 'Updated successfully' : 'Failed to update',
              icon: insert_programs_progressrecordtable_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_progressrecordtable_one.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },

      })
    },
    deleteMilestone(id) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this milestone?', {
        title: 'Confirm Delete',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes, Delete',
        cancelTitle: 'Cancel',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            // Graphql mutation to set is_deleted to true in programs_progressstagetable with id
            this.$apollo.mutate({
              mutation: gql`mutation {
                update_programs_progressstagetable_by_pk(pk_columns: {id: ${id}}, _set: {is_deleted: true}) {
                  id
                }
              }`,
              update: (store, { data: { update_programs_progressstagetable_by_pk } }) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: update_programs_progressstagetable_by_pk.id ? 'Milestone deleted successfully' : 'Failed to delete',
                    icon: update_programs_progressstagetable_by_pk.id ? 'CheckIcon' : 'XIcon',
                    variant: update_programs_progressstagetable_by_pk.id ? 'success' : 'warning',
                  },
                })
                this.$apollo.queries.rows.refetch()
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Delete cancelled',
                icon: 'XIcon',
                variant: 'warning',
              },
            })
          }
        })
    },
    editMilestone(id) {
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_progressstagetable_by_pk(pk_columns: {id: ${id}}, _set: {title: "${this.milestoneTitle.trim()}"}) {
            id
          }
        }`,
        update: (store, { data: { update_programs_progressstagetable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_programs_progressstagetable_by_pk.id ? 'Milestone updated successfully' : 'Failed to update',
              icon: update_programs_progressstagetable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_programs_progressstagetable_by_pk.id ? 'success' : 'warning',
            },
          })
          this.milestoneTitle = null
          this.$apollo.queries.rows.refetch()
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_progressstagetable(where: {startup_participant_id: {_eq: ${this.$route.params.aid} }}, order_by: {stage_number: asc}){
            id
            stage_number
            title
            programs_progressrecordtables{
              recommendations
              status
              id
              participant_id
              progress_stage_id
            }
          }
        }`
      },
      update(data) {
        data.programs_progressstagetable.forEach(e => {
          if (e.programs_progressrecordtables.length < 1) {
            e.programs_progressrecordtables.push({
              recommendations: '',
              status: '',
              participant_id: this.$route.params.aid,
              progress_stage_id: e.id,
            })
          }
        })
        return data.programs_progressstagetable
      },
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
